import React, { useCallback, useEffect, useMemo } from 'react';
import CollapsibleTable, { TableActionCell, TableConfig } from '../components/CollapsibleTable';
import { Box, Button, Grid, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Loader } from '../components/Loader';
import NotificationsModal from '../modals/notifications/NotificationsModal';
import { useAppDispatch } from '../hooks';
import { deleteNotification, getNotificationsAsync } from '../redux/thunkActions/notificationsActions';
import { useNotifications } from '../redux/selectors/useNotifications';

export function Notifications() {
  const notifications = useNotifications();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNotificationsAsync());
  }, [dispatch]);

  const onEdit = useCallback(
    (id: string) => {
      const currentNotification = notifications?.filter((el) => el.id === id)[0];

      NotificationsModal.show({ notification: currentNotification });
    },
    [notifications],
  );

  const onDelete = useCallback(
    (id: string) => {
      dispatch(deleteNotification(id));
    },
    [dispatch],
  );

  const tableConfig: TableConfig = useMemo(
    () => ({
      head: ['Имя', 'Включено?', 'Крон паттерн', 'Текст', ''].map((label) => ({ label })),
      rows: notifications
        ? notifications.map((item, i) => ({
            row: [
              <Box key={i}>{item.name}</Box>,
              <Box key={i}>{!item.disabled ? 'Да' : 'Нет'}</Box>,
              <Box key={i}>{item.cronPattern}</Box>,
              <Box
                key={i}
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical',
                }}>
                {item.text}
              </Box>,
              <TableActionCell key={i}>
                <IconButton onClick={() => onEdit(item.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onDelete(item.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableActionCell>,
            ],
          }))
        : [],
    }),
    [notifications, onEdit, onDelete],
  );

  return (
    <Grid container padding={2} direction={'column'}>
      <Loader isLoading={false} />
      <Grid item container spacing={2} direction={'row'} alignItems={'center'} justifyContent={'end'}>
        <Grid item>
          <Button onClick={() => NotificationsModal.show()} variant={'contained'}>
            Добавить
          </Button>
        </Grid>
      </Grid>
      <Grid item paddingY={2}>
        {notifications && <CollapsibleTable config={tableConfig} isCollapsible={false} isSmall={false} />}
      </Grid>
    </Grid>
  );
}
